/*
 * 업무구분: 고객
 * 화 면 명: MSPCM220M
 * 화면설명: 미승인고객
 * 작 성 일: 2023.03.06
 * 작 성 자: 김연숙
 */
<template>
  <ur-page-container class="msp" title="미승인 고객" :show-title="true" type="subpage" action-type="search" @on-header-left-click="fn_HistoryBack" @action-search-click="fn_OpenMSPCM116P()"
   :topButton="true" :topButtonBottom="topButtonBottom">
    <template #frame-header-fixed>
      <ur-box-container v-if="showReturnStr && !isInit" alignV="start"  direction="column" class="search-result-box bd-b-Ty1"> 
        <strong class="tit">검색결과</strong>
        <mo-tab-box default-idx="1" primary class="ns-tabs-box-o nw-tab-sub ns-tabs-box2 no-tab">
          <mo-tab-label idx="1" v-if="isMngr && pOrgData220M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData220M.hofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData220M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData220M.dofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData220M !== {}" @click="fn_OpenMSPCM116P">
            <span class="txt">{{pOrgData220M.fofOrg.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-if="isMngr && pOrgData220M !== {}" @click="fn_OpenMSPCM116P">
            <span class="tit">컨설턴트</span>
            <span class="txt">{{pOrgData220M.cnslt.label}}</span> 
          </mo-tab-label>
          <mo-tab-label idx="1" v-show="lv_ReturnStr[0] !== ''" @click="fn_OpenMSPCM116P">
            <span class="tit">고객</span>
            <span class="txt">{{lv_ReturnStr[0]}}</span> 
          </mo-tab-label>
        </mo-tab-box>
      </ur-box-container>
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <mo-checkbox v-model="isOneOrMoreCheck" @click="fn_CheckAll()" :disabled="isEmptyView || checkDisabled" size="small" />
          <div v-if="checkCount === 0" class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 <strong class="crTy-blue3">{{ totCnt }}</strong> 명</span>
            </div>
          </div>
          <div v-else class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum"><strong class="crTy-blue3">{{ checkCount }}</strong> 명 선택</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>
    <!-- Content영역 -->
    <ur-box-container v-if="!isEmptyView" direction="column" alignV="start" class="msp-cm-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-list">
        <mo-list :list-data="cmUnAprvCustInfoSVO">
          <template #list-item="{index, item}">
            <mo-list-item :class="{'on' : item.checked === true}" @click.native.stop="fn_OnClickListItem(index)">
              <mo-checkbox v-model="item.checked" @input="fn_CheckSelectedItem(item)" :disabled="checkDisabled" :ref="`chk${index}`"/>
              <div class="list-item__contents">
                <div class="list-item__contents__title">
                  <span class="name txtSkip">{{item.custNm}}</span>
                </div>
                <!-- 삭제예정일, 성별 -->
                <div class="list-item__contents__info">
                  <span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.dob)}}</span><em>|</em><span class="crTy-bk1">{{item.custSxdsNm}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span class="crTy-bk7 fs14rem mr10">주고객명</span><span class="crTy-bk1">{{item.mnCustNm}}</span><em>|</em>
                  <span class="crTy-bk7 fs14rem mr10">관계</span><span class="crTy-bk1">{{item.custRltnNm}}</span>
                </div>
                <div class="list-item__contents__info">
                  <span class="crTy-bk7 fs14rem mr10">승인요청일</span><span class="crTy-bk1">{{$commonUtil.dateDashFormat(item.fstRegYmd)}}</span>
                </div>
              </div>
            </mo-list-item>
          </template>
        </mo-list>   
      </ur-box-container>
      <!-- 
      <mo-bottom-sheet ref="nsbottomsheet1" class="ns-bottom-sheet" noHeader>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-alarm-menu">
            <mo-segment-wrapper v-model="groupSelectedItems" point>
              <mo-segment-button value="1">진료비</mo-segment-button>
              <mo-segment-button value="2">고객카드 삭제</mo-segment-button>
              <mo-segment-button value="3">고객카드 삭제</mo-segment-button>
              <mo-segment-button value="4">고객카드 삭제</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </template>
      </mo-bottom-sheet> -->


      <!-- 승인요청세대 삭제 bottomSheet -->
      <mo-bottom-sheet ref="bottomSheetAgree" :close-btn="false" :class="positionUp ? 'ns-bottom-sheet ns-style3 only-btn positionUp' : 'ns-bottom-sheet ns-style3 only-btn'" noHeader>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative-bs noshadow">
            <div class="relative-div">
              <!-- 메뉴 권한 입력된 후 주석 해제 -->
              <!-- <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_OpenMSPCM048P" v-if="selectItems.length === 1" v-is-enable="{acl: ['I']}" :disabled="selectItems[0].custRltnCd !== '01'" class="bgcolor-2 bd-radius-6 blue">승인요청</mo-button> -->
              <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_BtnMSPCM048P" v-if="selectItems.length === 1 && ['FC', 'TC'].includes(userInfo.chnlScCd)" :disabled="selectItems[0].custRltnCd !== '01'" class="bgcolor-2 bd-radius-6 blue">승인요청</mo-button>
              <mo-button componentid="" color="primary" shape="primary" size="medium" @click="fn_CnfrmPop('Delt')" class="bgcolor-2 bd-radius-6 blue">미승인 고객삭제</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <mo-bottom-sheet ref="bottomSheetBackCnfrm" class="ns-bottom-sheet ns-style3">
        <div class="customer-info-txt txt-center">
          <p class="ment" v-html="lv_Msg"/>
        </div>
        <template v-slot:action>
          <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
            <div class="relative-div">
              <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CnfrmPop('Cancel')">취소</mo-button>
              <mo-button v-if="lv_Flag === 'Aprv'" componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_OpenMSPCM048P">확인</mo-button>
              <mo-button v-else-if="lv_Flag === 'Delt'" componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CnfrmPop('Confirm')">확인</mo-button>
            </div>
          </ur-box-container>
        </template>
      </mo-bottom-sheet>

      <!-- Toast -->
      <mo-snackbar ref="snackbar" :duration="3000"></mo-snackbar>
    </ur-box-container>

    <!-- NoData 영역  -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>

    <div class="ns-height120"></div>
  </ur-page-container>
</template>

<script>

/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import MSPCM048P from '@/ui/cm/MSPCM048P' // 승인요청발송
import MSPCM116P from '@/ui/cm/MSPCM116P' // 조회조건팝업
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Screen from '~systems/mixin/screen'

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM220M',
  // 현재 화면 ID
  screenId: 'MSPCM220M',
  mixins: [Screen],
  // 컴포넌트 선언
  components: {
    MSPCM048P,
    MSPCM116P,
  },

  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      toastMsg: '', // 문자발송 toast 문구
      
      // 제어 변수
      isSearched: false,        // 검색 실행 여부
      isInit: false,            // 검색 초기화 여부
      isFirst: true,            // 초회 검색 여부
      isMngr: false,            // 지점장 여부
      isOneOrMoreCheck: false,  // 전체선택
      positionUp: false,        // 고객동의 바텀시트 강제 여부
      checkDisabled: false,     // 지점장 체크박스 선택 가능여부

      // 팝업 연동
      pOrgData220M: {},   // 최근 조직 조회 데이터
      popup048: {},       // MSPCM048P 승인요청발송
      popup116: {},       // MSPCM116P 전체고객 목록 검색
      lv_inputParamS: {}, // 조회 Obj
      lv_ReturnStr: [],   // 상세조회조건 텍스트 배열
      
      // 통신 변수
      custRltnList: [],        // 관계 정보
      nowDate: this.$bizUtil.getDateToString(new Date(), ''),
      cmUnAprvCustInfoSVO: [], // 인피니트 바인딩 리스트VO
      userInfo: {},            // 사용자정보 obj
      isLoading: false,
      isEmptyView: false,      // 데이터 없음 화면
      lv_cnsltNo: '',          // 컨설턴트 번호
      lv_inputParam: {         // 조회 서비스호출 Obj
        hofOrgNo: '',          // 사업부조직번호
        dofOrgNo: '',          // 지역단조직번호
        fofOrgNo: '',          // 지점조직번호
        cnsltNo: '',           // 컨설턴트번호
        inqrScCd: '',          // 조회구분코드
        custScCd: ''           // 고객구분코드
      },
      totCnt: 0,               // 누적 총 조회건수
      lv_inputParamD: {        // 삭제 서비스호출 Obj
        cnsltNo: '',
        custNm: '',
        cMUnAprvCustInfoList: []
      },
      searchKeyword: '',       // 고객명
      lv_Flag: '',             // 바텀시트 확인버튼 종류
      lv_Msg: '',              // 바텀시트 메시지

      // 더보기 key
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}],
      stndKeyVal: 'start',
      pageRowCnt: '20', // 페이지 카운트
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    selectItems() {
      return this.cmUnAprvCustInfoSVO.filter(item => item.checked)
    },
    checkCount () {
      return this.cmUnAprvCustInfoSVO.filter(item => item.checked).length
    },
    showReturnStr () {
      if (this.isMngr && !_.isEmpty(this.pOrgData220M)) {
        return true
      }
      if (this.lv_ReturnStr.length > 0) {
        for (let i in this.lv_ReturnStr) {
          if (!this.$bizUtil.isEmpty(this.lv_ReturnStr[i])) return true
        }
      }
      return false
    },
    topButtonBottom () {
      // topButton을 positionUp 클래스가 적용된 바텀시트의 위쪽으로 올라오게 함
      if (this.positionUp) return 150
      else return 0
    }
  },

  watch: {
    checkCount () {
      if (this.checkCount === this.cmUnAprvCustInfoSVO.length && this.cmUnAprvCustInfoSVO.length !== 0) {
        this.isOneOrMoreCheck = true
      } else {
        this.isOneOrMoreCheck = false
      }
    }
  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    let lv_Vm = this
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    // 사용자 정보
    this.userInfo = this.getStore('userInfo').getters.getUserInfo
    this.lv_cnsltNo = this.userInfo.userId // 컨설턴트 번호
    // 지점장 여부 확인 :: 해당 사용자는 본인이 아닌 FC번호로 조회가능
    if ( this.$cmUtil.isSrchOrgCombo() === 'Y') {
      this.isMngr = true
    }

    // 공통코드 조회
    this.$commonUtil.selListEACommCd('ZA_CUST_RLTN_CD').then(function () {
      lv_Vm.custRltnList = lv_Vm.getStore('code').getters.getState.get('ZA_CUST_RLTN_CD')
      // 미승인고객 조회
      lv_Vm.fn_Submit()
    })
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },


  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)

    this.fn_InitBottomSheet()
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    fn_OnClickListItem(index) {
      if (this.checkDisabled) return
      this.$refs[`chk${index}`].onClick()
    },

    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.checkCount > 0) this.fn_confirm()
      else {
        if (this.isSearched) {
          // 조회를 한번이라도 한 경우 전체 검색
          // 초기화
          this.pOrgData220M = {}  // 조직검색정보
          this.isSearched = false // 검색이력
          this.isInit = true
          this.isFirst = true
          this.fn_InitBottomSheet() // 바텀시트
  
          // 재조회
          this.fn_Submit()
        } else {
          // 조회를 하지 않았으면 뒤로가기
          if (this.$route.query?._isMenuItem) {
            this.$router.push({name: 'MSPBC002M'})
            return
          }
          this.$router.go(-1)
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_Submit
     * 설명       : 조회 버튼 클릭 이벤트
     ******************************************************************************/
    fn_Submit () {      
      // user와 선택한 컨설턴트가 다를 경우 체크박스 비활성화
      if (!_.isEmpty(this.pOrgData220M) && this.isMngr === true) {
        if (this.userInfo.userId !== this.pOrgData220M.cnslt.key) {
          this.checkDisabled = true
        } else {
          this.checkDisabled = false
        }
      } else {
        // 초기 접근 시
        this.checkDisabled = false
      }

      this.totCnt = 0
      this.stndKeyVal = 'start' // 더보기 최초조회
      this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}] // 초기로드 or 조회버튼시
      this.cmUnAprvCustInfoSVO = [] // 인피니트 바인딩 데이터
      this.isEmptyView = false // 데이터가 없습니다 visible 여부
      this.fn_DataSet() // 조회 파라미터 세팅

      // post 호출 전 Progress 타입 설정
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.fn_GetService() // 서비스 호출
    },

    /******************************************************************************
     * Function명 : fn_DataSet
     * 설명       : 조회전 데이터 파라미터 세팅
     ******************************************************************************/
    fn_DataSet () {
      // 초회 조회 조건 세팅
      if (this.isFirst) {
        this.pOrgData220M = {}  // 상세조회조건
        this.searchKeyword = ''
        this.lv_inputParam.custScCd = ''
        this.lv_inputParam.cnsltNo = this.lv_cnsltNo // 컨설턴트번호
        this.lv_inputParam.dofOrgNo = this.userInfo.onpstDofNo // 지역단조직번호
        this.lv_inputParam.fofOrgNo = this.userInfo.onpstFofOrgNo // 지점조직번호
        this.lv_inputParam.hofOrgNo = this.userInfo.onpstHofOrgNo // 소속사업부조직번호
        this.lv_inputParamD.cnsltNo = this.lv_cnsltNo // 컨설턴트번호

        this.isFirst = false
      }

      // 지점장 조회시 구분
      if (!_.isEmpty(this.pOrgData220M) && this.isMngr === true) {
        this.lv_inputParam.cnsltNo  = this.pOrgData220M.cnslt.key // 선택한 컨설턴트 번호
      } else {
        this.lv_inputParam.cnsltNo  = this.lv_cnsltNo // 컨설턴트 번호
      }
      this.lv_inputParam.custScCd = '02' // 대상고객 구분 코드(01:필수컨설팅동의 1개월전, 02:동의종료예정, 03:마케팅동의 1개월전)
    },

    /******************************************************************************
     * Function명 : fn_GetService
     * 설명       : 서비스 호출(미승인고객 목록 조회)
     ******************************************************************************/
    fn_GetService () {
      if (this.stndKeyVal === 'end') return

      // const trnstId = 'txTSSCM13S0'// 미승인고객 조회
      const trnstId = 'txTSSCM48S3'// 미승인고객 조회
      const auth = 'S'
      const lv_Vm = this
      let pParams = this.lv_inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.cMUnAprvCustInfoList

            if (responseList !== null) {
              // 리스트 체크변수 적용
              for (let i = 0; i < responseList.length; i++) {
                responseList[i].checked = false
                responseList[i].custRltnNm = lv_Vm.custRltnList.filter(x => x.cdVal === responseList[i].custRltnCd)[0].cdValNm
                responseList[i].deltParngYmd = lv_Vm.$bizUtil.getDateToString(moment(responseList[i].fstRegYmd).add(5, 'd').toDate(), '-')
              }

              if (lv_Vm.stndKeyVal !== 'start' ) { // 더보기
                for (let i = 0; i < responseList.length; i++) {
                  lv_Vm.cmUnAprvCustInfoSVO.push(responseList[i])
                }
              } else { // 1페이지
                lv_Vm.cmUnAprvCustInfoSVO = responseList
              }

              // 검색조건이 있는 경우 filter
              if (!lv_Vm.$bizUtil.isEmpty(lv_Vm.searchKeyword)) {
                let regex = new RegExp(`^${lv_Vm.searchKeyword}`)
                lv_Vm.cmUnAprvCustInfoSVO = lv_Vm.cmUnAprvCustInfoSVO.filter(item => regex.test(item.custNm))
              }

              if (responseList.length === 0) {
                lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
              } else {
                lv_Vm.isEmptyView = false // 데이터가 없습니다 visible false
              }

              // 조회 총 카운트
              lv_Vm.totCnt = lv_Vm.cmUnAprvCustInfoSVO.length
              // 더보기 키값
              lv_Vm.stndKeyList = response.trnstComm.stndKeyList
              if (lv_Vm.stndKeyList === null || lv_Vm.stndKeyList[0] === null || lv_Vm.stndKeyList[0].stndKeyVal === null || lv_Vm.stndKeyList[0].stndKeyVal.trim() === '') {
              // 다음페이지 데이터 존재안함(마지막 페이지)
                lv_Vm.stndKeyVal = 'end'
              } else {
              // 다음페이지 데이터 존재함
                lv_Vm.stndKeyVal = 'more'
              }
            } else {
              lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            }
          } else {
            lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          lv_Vm.isEmptyView = true // 데이터가 없습니다 visible true
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_DataSetD
      * 설명       : 삭제전 데이터 파라미터 세팅
    ******************************************************************************/
    fn_DataSetD () {
      if (this.lv_inputParamD.cMUnAprvCustInfoList !== null && this.lv_inputParamD.cMUnAprvCustInfoList.length > 0) {
        this.lv_inputParamD.cMUnAprvCustInfoList = []
      }
      this.lv_inputParamD.cMUnAprvCustInfoList = this.selectItems
    },

    /******************************************************************************
      * Function명 : fn_GetServiceD
      * 설명       : 서비스 호출(미승인고객)
    ******************************************************************************/
    fn_GetServiceD () {
      const trnstId = 'txTSSCM13D0'//  삭제
      const auth = 'S'

      const lv_Vm = this
      let pParams = this.lv_inputParamD
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.cMUnAprvCustInfoList
            if (responseList !== null) {
            }

            // 미승인고객 리스트 삭제처리
            lv_Vm.fn_GetServiceD1()
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_GetServiceD1
      * 설명       : 서비스 호출(미승인고객 리스트 삭제)
    ******************************************************************************/
    fn_GetServiceD1 () {
      let inputParam = {}
      inputParam.aprvCustList = this.selectItems

      const trnstId = 'txTSSCM34D0'
      const auth = 'S'
      const lv_Vm = this
      let pParams = inputParam
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            // 초기화
            lv_Vm.pOrgData220M = {} // 조직 조회 데이터
            lv_Vm.fn_InitBottomSheet()

            lv_Vm.fn_Submit() // 재조회
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
      * Function명 : fn_LoadingData
      * 설명       : 인피니트 로딩데이터
    ******************************************************************************/
    fn_LoadingData () {
      if (!this.isLoading) {
        // 더보기 시 전체체크 되어있으면 전체체크 해제
        if (this.isOneOrMoreCheck && this.stndKeyVal !== 'end') {
          this.isOneOrMoreCheck = false
        }

        // post 호출 전 Progress 타입 설정
        window.vue.getStore('progress').dispatch('PART')

        this.fn_GetService()
      }
    },

    /******************************************************************************
     * Function명 : fn_CheckAll
     * 설명       : 조회목록 전체선택
     ******************************************************************************/
    fn_CheckAll () {
      let lv_Vm = this

      this.$nextTick(() => {
        if (lv_Vm.cmUnAprvCustInfoSVO.length > 0) { // 리스트 존재시에만 작동
          for (let n in lv_Vm.cmUnAprvCustInfoSVO) {
            if (lv_Vm.isOneOrMoreCheck) {
              lv_Vm.cmUnAprvCustInfoSVO[n].checked = true
            } else {
              lv_Vm.cmUnAprvCustInfoSVO[n].checked = false
            }
          }
  
          // 체크 시 BottomSheet 컨트롤
          // 공통 시트를 가리고 사용자 시트를 보여준다.
          if (lv_Vm.checkCount > 0) {
            this.positionUp = true
            lv_Vm.$refs.bottomSheetAgree.open()  // 대상제외 메시지 바텀시트
          } else {
            this.fn_InitBottomSheet()
          }
        }
      })
    },

    /******************************************************************************
     * Function명 : fn_CheckSelectedItem
     * 설명       : 체크 핸들러
     ******************************************************************************/
    fn_CheckSelectedItem (item) {
      let lv_Vm = this

      if (item.checked) {
        // 모달 호출
        lv_Vm.positionUp = true
        lv_Vm.$refs.bottomSheetAgree.open()
      } 
      if (lv_Vm.checkCount === 0) {
        lv_Vm.fn_InitBottomSheet()
      }
    },

    /******************************************************************************
     * Function명 : fn_InitBottomSheet
     * 설명       : 바텀시트 초기화
     ******************************************************************************/
    fn_InitBottomSheet () {
      let lv_Vm = this

      // 바텀시트 초기화
      if (lv_Vm.$refs !== undefined) {
        if (lv_Vm.$refs.bottomSheetAgree !== undefined) {
          lv_Vm.positionUp = false
          lv_Vm.$refs.bottomSheetAgree.close()
        }
        if (lv_Vm.$refs.bottomSheetBackCnfrm !== undefined) {
          lv_Vm.$refs.bottomSheetBackCnfrm.close()
        }
      }
    },

    /******************************************************************************
     * Function명 : fn_BtnMSPCM048P
     * 설명       : 승인요청발송 팝업 호출 전 구비서류 유무 검사
     ******************************************************************************/
    fn_BtnMSPCM048P () {
      if (this.checkCount > 1) return

      const trnstId = 'txTSSCM14S0'
      const auth = 'S'
      const lv_Vm = this
      let pParams = {
        chnlCtgrId: lv_Vm.selectItems[0].chnlCstgrId,   // 채널그룹ID
        chnlCustId: lv_Vm.selectItems[0].chnlCustId     // 채널고객ID
      }
      let confirm = this.getStore('confirm')

      this.isLoading = true
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.msgComm !== null && response.msgComm.msgCd === 'EFWS003') {
            let responseList = response.body.aprvCustList
            if (!_.isEmpty(responseList)) {
              //이미지네임 존재할 경우 알럿
              if (!lv_Vm.$bizUtil.isEmpty(responseList[0].foreImgNm) || !lv_Vm.$bizUtil.isEmpty(responseList[0].backImgNm)) {
                lv_Vm.fn_CnfrmPop('Aprv')
              } else lv_Vm.fn_OpenMSPCM048P() 
            } else lv_Vm.fn_OpenMSPCM048P()
          } else {
            confirm.dispatch('ADD', response.msgComm.msgDesc)
          }
          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },

    /******************************************************************************
     * Function명: fn_OpenMSPCM116P
     * 설명: 고객명 검색 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM116P () {
      this.popup116 = this.$bottomModal.open(MSPCM116P, {
        properties: {
          pPage: 'MSPCM220M',
          pOrgData: this.pOrgData220M, // 최근 조직 조회 데이터
          pIsMngr: this.isMngr, // 지점장 여부
          pSearchKeyword: this.searchKeyword, // 고객명
        },
        listeners: {
          onPopupSearch: (pData) => {
            // 모달 닫기
            this.$bottomModal.close(this.popup116)
            this.fn_InitBottomSheet()

            // 마지막 조직 조회 데이터 셋팅
            this.pOrgData220M = pData.rtnData.pOrgData
            if (this.isMngr) {
              this.lv_inputParam.hofOrgNo = this.pOrgData220M.hofOrg.key
              this.lv_inputParam.dofOrgNo = this.pOrgData220M.dofOrg.key
              this.lv_inputParam.fofOrgNo = this.pOrgData220M.fofOrg.key
            }
            // 검색 실행 여부
            this.isSearched = true
            // 검색 초기화 여부
            this.isInit = pData.isInit
            if (this.isInit) this.isSearched = false
            // 조회조건 텍스트 세팅
            this.lv_ReturnStr = pData.srchOpt
            // 나머지 데이터 세팅
            this.searchKeyword = pData.rtnData.searchKeyword

            // 미승인고객 조회
            this.fn_Submit()
          }
        }
      })
    },

    /******************************************************************************
     * Function명: fn_OpenMSPCM048P
     * 설명: 승인요청발송 팝업 호출
     ******************************************************************************/
    fn_OpenMSPCM048P () {
      this.popup048 = this.$bottomModal.open(MSPCM048P, {
        properties: {
          pCustCardVOList: this.selectItems
        },
        listeners: {
          onPopupCancel: () => {
            this.$bottomModal.close(this.popup048)
            this.fn_InitBottomSheet()
          }
        }
      })
    },

    /******************************************************************************
    * Function명 : fn_CnfrmPop
    * 설명       : 컨펌 팝업 관리
    *           - Delt(미승인고객카드삭제), Cancel(화면유지), Confirm(삭제서비스실행) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_CnfrmPop (param) {
      this.lv_Flag = param
      switch(param){
        case 'Aprv':
          this.lv_Msg = "재발송 시 구비서류를<br>새로 제출해야 합니다."
          this.$refs.bottomSheetBackCnfrm.open()
          break
        case 'Delt':
          this.lv_Msg = "선택한 고객의 세대원 중 '승인요청' 단계에 머물러 있는 모든 세대원의 승인요청이 삭제됩니다. 삭제신청 후 취소는 불가능합니다.<br>삭제하시겠습니까?"
          this.$refs.bottomSheetBackCnfrm.open()
          break
        case 'Cancel':
          this.$refs.bottomSheetBackCnfrm.close()
          break
        case 'Confirm':
          //Confirm : 승인요청세대 삭제
          this.$refs.bottomSheetBackCnfrm.close()
          // 삭제 파라미터 세팅 및 서비스 실행
          this.fn_DataSetD()
          this.fn_GetServiceD()
          break
      }
      
    },

    /******************************************************************************
     * @description confirm popup
     * @param {String} title 제목
     * @param {String} message confirm 내용
     ******************************************************************************/
    fn_confirm(title, message) {
      let lv_Vm = this
      let backMsg = '선택을 그만두고<br>이전 화면으로 이동하시겠습니까?'
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            type: 'B',
            content: backMsg,
            title_pos_btn: "예",
            title_neg_btn: "아니오"
          },
          listeners: {
            onPopupConfirm: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
              if (this.isSearched) {
                // 조회를 한번이라도 한 경우 전체 검색
                // 초기화
                this.pOrgData220M = {}  // 조직검색정보
                this.isSearched = false // 검색이력
                this.isInit = true
                this.isFirst = true
                this.fn_InitBottomSheet() // 바텀시트

                // 재조회
                this.fn_Submit()
              } else {
                // 조회를 하지 않았으면 뒤로가기
                if (this.$route.query?._isMenuItem) {
                  this.$router.push({name: 'MSPBC002M'})
                  return
                }
                this.$router.go(-1)
              }
            },
            onPopupClose: () => {
              lv_Vm.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
  }
}
</script>
